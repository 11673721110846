/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const IAddressedMatterE3Enum = {
  WaterAndMarineResourcesMemberEsrsWaterConsumptionMember:
    "esrs:WaterAndMarineResourcesMember.esrs:WaterConsumptionMember",
  WaterAndMarineResourcesMemberEsrsWaterWithdrawalsMember:
    "esrs:WaterAndMarineResourcesMember.esrs:WaterWithdrawalsMember",
  WaterAndMarineResourcesMemberEsrsWaterDischargesMember:
    "esrs:WaterAndMarineResourcesMember.esrs:WaterDischargesMember",
  WaterAndMarineResourcesMemberEsrsWaterDischargesInOceansMember:
    "esrs:WaterAndMarineResourcesMember.esrs:WaterDischargesInOceansMember",
  WaterAndMarineResourcesMemberEsrsExtractionAndUseOfMarineResourcesMember:
    "esrs:WaterAndMarineResourcesMember.esrs:ExtractionAndUseOfMarineResourcesMember",
  OtherWaterAndMarineResourcesSubtopicMember: "esrs:OtherWaterAndMarineResourcesSubtopicMember",
} as const;

export type IAddressedMatterE3Enum =
  (typeof IAddressedMatterE3Enum)[keyof typeof IAddressedMatterE3Enum];
