/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const ISustainableDevelopmentGoalEnum = {
  EndPovertyInAllItsFormsEverywhereMember: "esrs:EndPovertyInAllItsFormsEverywhereMember",
  EndHungerAchieveFoodSecurityAndImprovedNutritionAndPromoteSustainableAgricultureMember:
    "esrs:EndHungerAchieveFoodSecurityAndImprovedNutritionAndPromoteSustainableAgricultureMember",
  EnsureHealthyLivesAndPromoteWellbeingForAllAtAllAgesMember:
    "esrs:EnsureHealthyLivesAndPromoteWellbeingForAllAtAllAgesMember",
  EnsureInclusiveAndEquitableQualityEducationAndPromoteLifelongLearningOpportunitiesForAllMember:
    "esrs:EnsureInclusiveAndEquitableQualityEducationAndPromoteLifelongLearningOpportunitiesForAllMember",
  AchieveGenderEqualityAndEmpowerAllWomenAndGirlsMember:
    "esrs:AchieveGenderEqualityAndEmpowerAllWomenAndGirlsMember",
  EnsureAvailabilityAndSustainableManagementOfWaterAndSanitationForAllMember:
    "esrs:EnsureAvailabilityAndSustainableManagementOfWaterAndSanitationForAllMember",
  EnsureAccessToAffordableReliableSustainableAndModernEnergyForAllMember:
    "esrs:EnsureAccessToAffordableReliableSustainableAndModernEnergyForAllMember",
  PromoteSustainedInclusiveAndSustainableEconomicGrowthFullAndProductiveEmploymentAndDecentWorkForAllMember:
    "esrs:PromoteSustainedInclusiveAndSustainableEconomicGrowthFullAndProductiveEmploymentAndDecentWorkForAllMember",
  BuildResilientInfrastructurePromoteInclusiveAndSustainableIndustrializationAndFosterInnovationMember:
    "esrs:BuildResilientInfrastructurePromoteInclusiveAndSustainableIndustrializationAndFosterInnovationMember",
  ReduceInequalityWithinAndAmongCountriesMember:
    "esrs:ReduceInequalityWithinAndAmongCountriesMember",
  MakeCitiesAndHumanSettlementsInclusiveSafeResilientAndSustainableMember:
    "esrs:MakeCitiesAndHumanSettlementsInclusiveSafeResilientAndSustainableMember",
  EnsureSustainableConsumptionAndProductionPatternsMember:
    "esrs:EnsureSustainableConsumptionAndProductionPatternsMember",
  TakeUrgentActionToCombatClimateChangeAndItsImpactsMember:
    "esrs:TakeUrgentActionToCombatClimateChangeAndItsImpactsMember",
  ConserveAndSustainablyUseOceansSeasAndMarineResourcesForSustainableDevelopmentMember:
    "esrs:ConserveAndSustainablyUseOceansSeasAndMarineResourcesForSustainableDevelopmentMember",
  ProtectRestoreAndPromoteSustainableUseOfTerrestrialEcosystemsSustainablyManageForestsCombatDesertificationAndHaltAndReverseLandDegradationAndHaltBiodiversityLossMember:
    "esrs:ProtectRestoreAndPromoteSustainableUseOfTerrestrialEcosystemsSustainablyManageForestsCombatDesertificationAndHaltAndReverseLandDegradationAndHaltBiodiversityLossMember",
  PromotePeacefulAndInclusiveSocietiesForSustainableDevelopmentProvideAccessToJusticeForAllAndBuildEffectiveAccountableAndInclusiveInstitutionsAtAllLevelsMember:
    "esrs:PromotePeacefulAndInclusiveSocietiesForSustainableDevelopmentProvideAccessToJusticeForAllAndBuildEffectiveAccountableAndInclusiveInstitutionsAtAllLevelsMember",
  StrengthenMeansOfImplementationAndRevitalizeGlobalPartnershipForSustainableDevelopmentMember:
    "esrs:StrengthenMeansOfImplementationAndRevitalizeGlobalPartnershipForSustainableDevelopmentMember",
} as const;

export type ISustainableDevelopmentGoalEnum =
  (typeof ISustainableDevelopmentGoalEnum)[keyof typeof ISustainableDevelopmentGoalEnum];
