/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const IAddressedMatterS1Enum = {
  WorkingConditionsForOwnWorkforceMemberEsrsSecureEmploymentForOwnWorkforceMember:
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:SecureEmploymentForOwnWorkforceMember",
  WorkingConditionsForOwnWorkforceMemberEsrsWorkingTimeForOwnWorkforceMember:
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:WorkingTimeForOwnWorkforceMember",
  WorkingConditionsForOwnWorkforceMemberEsrsAdequateWagesForOwnWorkforceMember:
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:AdequateWagesForOwnWorkforceMember",
  WorkingConditionsForOwnWorkforceMemberEsrsSocialDialogueForOwnWorkforceMember:
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:SocialDialogueForOwnWorkforceMember",
  WorkingConditionsForOwnWorkforceMemberEsrsFreedomOfAssociationForOwnWorkforceMember:
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:FreedomOfAssociationForOwnWorkforceMember",
  WorkingConditionsForOwnWorkforceMemberEsrsExistenceOfWorksCouncilsForOwnWorkforceMember:
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:ExistenceOfWorksCouncilsForOwnWorkforceMember",
  WorkingConditionsForOwnWorkforceMemberEsrsInformationConsultationAndParticipationRightsOfOwnWorkforceMember:
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:InformationConsultationAndParticipationRightsOfOwnWorkforceMember",
  WorkingConditionsForOwnWorkforceMemberEsrsCollectiveBargainingForOwnWorkforceMember:
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:CollectiveBargainingForOwnWorkforceMember",
  WorkingConditionsForOwnWorkforceMemberEsrsWorklifeBalanceForOwnWorkforceMember:
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:WorklifeBalanceForOwnWorkforceMember",
  WorkingConditionsForOwnWorkforceMemberEsrsHealthAndSafetyForOwnWorkforceMember:
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:HealthAndSafetyForOwnWorkforceMember",
  EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMemberEsrsGenderEqualityAndEqualPayForWorkOfEqualValueForOwnWorkforceMember:
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:GenderEqualityAndEqualPayForWorkOfEqualValueForOwnWorkforceMember",
  EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMemberEsrsTrainingAndSkillsDevelopmentForOwnWorkforceMember:
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:TrainingAndSkillsDevelopmentForOwnWorkforceMember",
  EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMemberEsrsEmploymentAndInclusionOfPersonsWithDisabilitiesForOwnWorkforceMember:
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:EmploymentAndInclusionOfPersonsWithDisabilitiesForOwnWorkforceMember",
  EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMemberEsrsMeasuresAgainstViolenceAndHarassmentInWorkplaceForOwnWorkforceMember:
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:MeasuresAgainstViolenceAndHarassmentInWorkplaceForOwnWorkforceMember",
  EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMemberEsrsDiversityForOwnWorkforceMember:
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:DiversityForOwnWorkforceMember",
  OtherWorkrelatedRightsForOwnWorkforceMemberEsrsChildLabourForOwnWorkforceMember:
    "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:ChildLabourForOwnWorkforceMember",
  OtherWorkrelatedRightsForOwnWorkforceMemberEsrsForcedLabourForOwnWorkforceMember:
    "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:ForcedLabourForOwnWorkforceMember",
  OtherWorkrelatedRightsForOwnWorkforceMemberEsrsAdequateHousingForOwnWorkforceMember:
    "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:AdequateHousingForOwnWorkforceMember",
  OtherWorkrelatedRightsForOwnWorkforceMemberEsrsPrivacyForOwnWorkforceMember:
    "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:PrivacyForOwnWorkforceMember",
  OtherOwnWorkforceSubtopicMember: "esrs:OtherOwnWorkforceSubtopicMember",
} as const;

export type IAddressedMatterS1Enum =
  (typeof IAddressedMatterS1Enum)[keyof typeof IAddressedMatterS1Enum];
