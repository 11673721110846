/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const IGroundsForDiscriminationEnum = {
  RacialAndEthnicOriginGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember:
    "esrs:RacialAndEthnicOriginGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember",
  ColourGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember:
    "esrs:ColourGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember",
  SexGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember:
    "esrs:SexGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember",
  SexualOrientationGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember:
    "esrs:SexualOrientationGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember",
  GenderIdentityGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember:
    "esrs:GenderIdentityGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember",
  DisabilityGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember:
    "esrs:DisabilityGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember",
  AgeGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember:
    "esrs:AgeGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember",
  ReligionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember:
    "esrs:ReligionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember",
  PoliticalOpinionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember:
    "esrs:PoliticalOpinionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember",
  NationalExtractionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember:
    "esrs:NationalExtractionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember",
  SocialOriginGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember:
    "esrs:SocialOriginGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember",
  OtherFormsOfDiscriminationCoveredByEuRegulationAndNationalLawAreSpecificallyCoveredInPolicyMember:
    "esrs:OtherFormsOfDiscriminationCoveredByEuRegulationAndNationalLawAreSpecificallyCoveredInPolicyMember",
} as const;

export type IGroundsForDiscriminationEnum =
  (typeof IGroundsForDiscriminationEnum)[keyof typeof IGroundsForDiscriminationEnum];
